<template>
  <div>
    <template v-if="financialDiscount || financialCreditsApplied">
      <ZTable class="mb-0">
        <ZTableLineItem
          v-if="financialDiscount"
          :value="formatPrice({ value: financialDiscount })"
        >
          <template #label>
            {{ t('booking.promo.promoCode') }}
            <strong v-if="financialPromoCode">{{ financialPromoCode }}</strong>
          </template>
        </ZTableLineItem>

        <ZTableLineItem
          v-if="financialCreditsApplied"
          :label="t('booking.youWere.appliedCredits')"
          :tooltip="t('booking.creditsToBeApplied.tooltip')"
          :value="formatPrice({ value: financialCreditsApplied })"
          value-data-testid="applied-credits"
        />

        <ZTableLineItem
          :label="t('booking.total')"
          :value="formatPrice({ value: calculatedTotal })"
          value-data-testid="calculated-total-amount"
          total
        />
      </ZTable>

      <hr>
    </template>

    <BookingPaymentScheduler />
  </div>
</template>

<script setup lang="ts">
import type { FinancialCommon, Nullable } from '~/types'

const props = defineProps<{
  financial: Nullable<FinancialCommon>
}>()

const {
  financialCountry,
  financialCreditsApplied,
  financialDiscount,
  financialPromoCode,
  financialRenterTotal,
  financialTotalCloseouts,
  financialTotalDamages,
  financialTotalPromotionalDiscountAmount,
} = useFinancialData(computed(() => props.financial))

const { t } = useI18n()
const { priceDisplay } = usePrice()

function formatPrice({ value }: { value: number }) {
  return priceDisplay({ value, countryCode: financialCountry.value })
}

const calculatedTotal = computed(() => {
  const renterTotal = financialRenterTotal.value
  const effectiveDiscount = financialDiscount.value - financialTotalPromotionalDiscountAmount.value
  return Math.max(
    renterTotal
    + financialTotalCloseouts.value
    + financialTotalDamages.value
    - (effectiveDiscount + financialCreditsApplied.value),
    0,
  )
})
</script>

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n src="~/locales/common/booking/credits-to-be-applied.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "booking": {
      "promo": {
        "promoCode": "Promo Code"
      },
      "youWere": {
        "appliedCredits": "Applied credits"
      }
    }
  },
  "fr": {
    "booking": {
      "promo": {
        "promoCode": "Code promotionel"
      },
      "youWere": {
        "appliedCredits": "Crédits appliqués"
      }
    }
  }
}
</i18n>
