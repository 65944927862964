<template>
  <template v-if="addonsList.length">
    <ZTableLineItem
      :discount="highlight"
      label-data-testid="show-payment-details-addons"
      :value="showAddons && !hasCustomTotalAddon ? undefined : formatPrice({ value: addonsTotal })"
      value-data-testid="total-addons-amount"
      is-clickable
      @click="showAddons = !showAddons"
    >
      <template #label>
        {{ t('booking.addons') }}
        <ZToggleArrow
          :toggled="showAddons"
          class="text-highlight"
        />
      </template>
    </ZTableLineItem>

    <ZTableLineItem
      v-for="addon in addonsList"
      v-show="showAddons"
      :key="addon.IdInRV"
      :label="getAddonLabel({ slug: addon.Slug ?? '', name: addon.Name ?? '' })"
      :label-data-testid="`addon-${addon.Slug}`"
      :value="addon.TotalAmount ? formatPrice({ value: addon.TotalAmount }) : t('included')"
      :nested="1"
    />
  </template>
</template>

<script setup lang="ts">
import type { FinancialCommon, Nullable } from '~/types'

const props = defineProps<{
  financial?: Nullable<FinancialCommon>
  highlight?: boolean
}>()

const {
  financialAddOns,
  financialTotalAddOns,
  financialTotalCleanings,
} = useFinancialData(computed(() => props.financial))

const { t } = useI18n()
const { getAddonLabel } = useAddon()

const { rvCountry } = useRvDetails()
const { priceDisplay } = usePrice()
function formatPrice({ value, showCurrencyCode }: { value: number, showCurrencyCode?: boolean }) {
  return priceDisplay({ value, countryCode: rvCountry.value, showCurrencyCode })
}

const showAddons = ref(false)

const hasCustomTotalAddon = computed(() => {
  return financialAddOns.value.some((addon) => addon.AddOnType === 'CustomTotal')
})

const addonsTotal = computed(() => {
  return financialTotalAddOns.value + (hasCustomTotalAddon.value ? financialTotalCleanings.value : 0)
})

const addonsList = computed(() => {
  return financialAddOns.value.filter((addon) => {
    // If there is no custom total addon, we only want to show RVAddOns
    if (!hasCustomTotalAddon.value) {
      return addon.AddOnType === 'RVAddOn'
    }
    // If there is a custom total addon, we only want to show RVAddOns and CleaningFee addons
    return !['Roadside', 'CustomTotal'].includes(addon.AddOnType)
  })
})
</script>

<i18n src="~/locales/common/booking.json" lang="json" />
